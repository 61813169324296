<template lang="html" src="./cardSerialNumberValidation.template.vue"></template>

<style lang="scss" src="./cardSerialNumberValidation.scss"></style>

<script>

const i18nData = require('./cardSerialNumberValidation.i18n');
export default {
  name: 'CardSerialNumberValidation',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  props: {
    part: {
      type: Object,
      required: true
    },
    serialNumberToolEnabled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
    };
  },
  created() {
   
  },
  methods: {
    activateSerialNumberTool() {
      this.$emit('activateSerialNumberTool');
    }
  },
};
</script>

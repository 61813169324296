<template lang="html" src="./cardMultipleObjectsValidation.template.vue"></template>

<style lang="scss" src="./cardMultipleObjectsValidation.scss"></style>

<script>

const i18nData = require('./cardMultipleObjectsValidation.i18n');
export default {
  name: 'CardMultipleObjectsValidation',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  props: {
    part: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
    };
  },
  created() {
   
  },
  methods: {
    activateObjectsSplitterTool() {
      this.$emit('activateObjectsSplitterTool');
    }
  },
};
</script>

<template lang="html" src="./cardPricesValidation.template.vue"></template>

<style lang="scss" src="./cardPricesValidation.scss"></style>

<script>

const i18nData = require('./cardPricesValidation.i18n');
export default {
  name: 'CardPricesValidation',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  props: {
    part: {
      type: Object,
      required: true
    },
    disableCardHighlight: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    };
  },
  computed: {
    isMissing() {
      return !this.part.homologation?.prices?.length;
    }
  },
  created() {
   
  },
  methods: {
    openPricesDialog() {
      this.$emit('openPricesDialog');
    },
  },
};
</script>

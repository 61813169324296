var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$part, _vm$part$attachments, _vm$part$attachments2;

        var hover = _ref.hover;
        return [_c('v-card', {
          staticClass: "component-cardAttachments",
          class: {
            'highlight': ((_vm$part = _vm.part) === null || _vm$part === void 0 ? void 0 : (_vm$part$attachments = _vm$part.attachments) === null || _vm$part$attachments === void 0 ? void 0 : _vm$part$attachments.length) > 0
          },
          attrs: {
            "width": "200px",
            "color": hover ? 'hover' : ''
          },
          on: {
            "click": _vm.openAttachments
          }
        }, [_c('v-tooltip', {
          attrs: {
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('div', _vm._g({}, on), [_c('v-card-title', {
                staticClass: "pt-1 pb-1 d-flex justify-space-between px-2"
              }, [_c('v-icon', {
                attrs: {
                  "color": "info",
                  "small": ""
                }
              }, [_vm._v(" far fa-file-alt ")]), _c('span', {
                staticClass: "text-subtitle-1"
              }, [_vm._v(" " + _vm._s(_vm.$tc('Attachments', _vm.part.attachments.length)) + " ")]), _c('v-icon', {
                attrs: {
                  "color": "info",
                  "small": ""
                }
              }, [_vm._v(" fas fa-ellipsis-h ")])], 1), _c('v-card-text', {
                staticClass: "py-0 px-2"
              }, [_c('v-img', {
                attrs: {
                  "src": require("../assets/attachments.png"),
                  "width": "100%",
                  "height": "128px",
                  "contain": ""
                }
              })], 1), _c('v-card-actions', {
                staticClass: "pt-1 pb-2 justify-center px-2"
              }, [_vm.part.attachments.length === 0 ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('AddAttachments')) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('ManageAttachments')) + " ")]), _c('v-icon', {
                staticClass: "ml-2",
                attrs: {
                  "small": "",
                  "color": "info"
                }
              }, [_vm._v(" fas fa-pen ")])], 1)], 1)];
            }
          }], null, true)
        }, [((_vm$part$attachments2 = _vm.part.attachments) === null || _vm$part$attachments2 === void 0 ? void 0 : _vm$part$attachments2.length) === 0 ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('YouCanAddAttachments')) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('AttachmentsSpecified', {
          attachments: _vm.part.attachments.length
        })) + " ")])])], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "content-class": "component-rejectHomologationDialog",
      "max-width": "800",
      "persistent": _vm.loading
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('RejectHomologationOfPart')) + " " + _vm._s(_vm.part.name) + " ")]), _c('v-card-text', [_c('v-divider', {
    staticClass: "mb-4"
  }), _c('p', {
    staticClass: "mb-2"
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "x-small": "",
      "color": "info"
    }
  }, [_vm._v(" fas fa-info-circle ")]), _c('span', {
    staticClass: "text-caption"
  }, [_vm._v(" " + _vm._s(_vm.$t('YourMessageWillBeTransmittedToTheCustomer')) + " ")])], 1), _c('v-autocomplete', {
    staticClass: "mb-4",
    attrs: {
      "outlined": "",
      "hide-details": "",
      "items": _vm.subjects,
      "dense": "",
      "no-filter": "",
      "disabled": _vm.loading
    },
    on: {
      "change": _vm.selectedSubjectChanged
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('Subject')) + ": ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.selectedSubject,
      callback: function callback($$v) {
        _vm.selectedSubject = $$v;
      },
      expression: "selectedSubject"
    }
  }), _c('v-textarea', {
    staticClass: "mb-1 pointer-events-none max-width-150",
    attrs: {
      "outlined": "",
      "rows": "1",
      "auto-grow": "",
      "disabled": "",
      "dense": "",
      "hide-details": "true",
      "value": "".concat(_vm.$t('Greetings'), ",")
    }
  }), _c('v-textarea', {
    staticClass: "mb-1",
    attrs: {
      "clearable": "",
      "outlined": "",
      "rows": "7",
      "hide-details": "auto",
      "rules": _vm.rules,
      "disabled": _vm.loading
    },
    model: {
      value: _vm.message,
      callback: function callback($$v) {
        _vm.message = $$v;
      },
      expression: "message"
    }
  }), _c('v-textarea', {
    staticClass: "mb-2 pointer-events-none",
    attrs: {
      "outlined": "",
      "rows": "1",
      "auto-grow": "",
      "disabled": "",
      "dense": "",
      "hide-details": "true",
      "value": "".concat(_vm.$t('KindRegards'), ", ").concat(_vm.$appConfig.supplier.name)
    }
  }), _c('p', {
    staticClass: "mb-0"
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "x-small": "",
      "color": "info"
    }
  }, [_vm._v(" fas fa-arrow-circle-right ")]), _c('span', {
    staticClass: "text-caption"
  }, [_vm._v(" " + _vm._s(_vm.$t('WeAdviseYouToPreciseMessage')) + " ")])], 1), _c('p', {
    staticClass: "mb-0"
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "x-small": "",
      "color": "info"
    }
  }, [_vm._v(" fas fa-info-circle ")]), _c('span', {
    staticClass: "text-caption"
  }, [_vm._v(" " + _vm._s(_vm.$t('HomologationWillBeSuspended')) + " ")])], 1)], 1), _c('v-card-actions', {
    staticClass: "justify-space-between"
  }, [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")]), _c('v-icon', {
    staticClass: "ml-2"
  }, [_vm._v(" far fa-times-circle ")])], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "loading": _vm.loading,
      "disabled": !_vm.message,
      "color": "info"
    },
    on: {
      "click": _vm.rejectPartHomologation
    }
  }, [_c('span', {
    staticClass: "font-weight-medium"
  }, [_vm._v(" " + _vm._s(_vm.$t('RejectHomologationAndSendMessage')) + " ")]), _c('v-icon', {
    staticClass: "ml-2"
  }, [_vm._v(" fas fa-paper-plane ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template lang="html" src="./pricingRangesV2.template.vue"></template>

<style lang="scss" src="./pricingRangesV2.scss"></style>

<script>
const i18nData = require('./pricingRangesV2.i18n');

import { FormulaPricing } from '@cloudmanufacturingtechnologies/portal-components';

import { BigNumber } from 'bignumber.js';

export default {
  name: 'PricingRangesV2',
  props: {
    part: {
      type: Object,
      required: true
    },
    supplier: {
      type: Object,
      required: true
    },
    editMode: {
      type: Boolean,
      default: false
    },
    displayCloseButton: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    part: {
      handler: function(newVal, oldVal) {
        if( JSON.stringify(newVal.slicerAnalysis) !== JSON.stringify(oldVal.slicerAnalysis)) {
          this.requiresInit = true;
        }
        if(this.requiresInit || JSON.stringify(newVal.homologation?.prices) !== JSON.stringify(oldVal.homologation?.prices)) {
          this.initPriceRanges();
          this.initNewLinePriceObject();
        }
      }
    },
    deep: true
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      percentage: 100,
      lastPercentageUpdate: null,
      quantities: [1, 2, 3, 4, 5, 10, 20, 50, 100],
      hiddenSortedPriceRanges: [],
      priceRanges: [],
      displayFastChangeButtons: false,
      displayRoundButtons: true,
      rulesNewLineQuantity: [
        (v) => v === null || v > 0 || this.$t('MustBeGreaterThan0'),
        (v) => v === null || Number.isInteger(Number(v)) || this.$t('MustBeInteger')
      ],
      rulesNewLinePrice: [
        (v) => v === null || v >= 0 || this.$t('MustBeGreaterOrEqualThan0'),
      ],
      newLinePriceObject: null,
      loading: false,
      requiresInit: true,
      algorithmVolumeVariableUsage: [],
    };
  },
  created() {
    this.detectAlgorithmVolumeType();
    this.initNewLinePriceObject();
    this.initPriceRanges();
  },
  computed: {
    hasPriceAlgorithm() {
      return this.supplier.prices.some(formulaObj => {return formulaObj.technology === this.part.homologation.technology && formulaObj.material === this.part.homologation.material; });
    },
    priceAlgorithm() {
      return this.supplier.prices.find(formulaObj => {return formulaObj.technology === this.part.homologation.technology && formulaObj.material === this.part.homologation.material; })?.formula;
    },
    canAddNewLineQuantity() {
      return !this.priceRanges.some(pObj => {return Number(pObj.quantity) === Number(this.newLinePriceObject.quantity);});
    },
    canAddNewLine() {
      return this.newLinePriceObject.quantity && this.newLinePriceObject.price !== null && this.canAddNewLineQuantity;
    },
    requiresRangeUpdate() {
      return this.priceRanges.some((priceObj, index) => {
        return index !== 0
          && priceObj.price !== null
          && (Number(priceObj.quantity) <= Number(this.priceRanges[index - 1].quantity)
            || Number(priceObj.price) === Number(Number(this.priceRanges[index - 1].price))
          );
      });
    },
    areRangesValid() {
      return this.priceRanges.every((priceObj, index) => {
        return Number.isInteger(Number(priceObj.quantity)) && (index === 0 || Number(priceObj.price) < Number(this.priceRanges[index - 1].price));
      });
    },
    isNewLineEmpty() {
      return (this.newLinePriceObject.quantity === null || this.newLinePriceObject.quantity === '')
        && (this.newLinePriceObject.price === null || this.newLinePriceObject.price === '');
    }
  },
  methods: {
    detectAlgorithmVolumeType() {
      if(this.hasPriceAlgorithm) {
        /**
         * Detect if sliceVolume* exist
         * falbback is volume
         * - part_volume
         * - support_volume
         * - total_volume
         * - volume (based on standard analysis)
         */
        const regexStandardVolume = /[^_](volume).*/;
        const algorithmVolumeVariableUsage = [];
        if(this.priceAlgorithm.includes('part_volume')) {
          algorithmVolumeVariableUsage.push('part_volume');
        }
        if(this.priceAlgorithm.includes('support_volume')) {
          algorithmVolumeVariableUsage.push('support_volume');
        }
        if(this.priceAlgorithm.includes('total_volume')) {
          algorithmVolumeVariableUsage.push('total_volume');
        }
        if(this.priceAlgorithm.match(regexStandardVolume)) {
          algorithmVolumeVariableUsage.push('volume');
        }
        this.algorithmVolumeVariableUsage = algorithmVolumeVariableUsage;
      }
    },
    improveVolumeReadability(volumeInMillimeter) {
      let improvedValue = 0;
      switch (true) {
      case volumeInMillimeter < 1e3:
        improvedValue = `${Math.ceil(volumeInMillimeter)} mm³`;
        break;
      case volumeInMillimeter >= 1e3 && volumeInMillimeter < 1e6:
        improvedValue = `${
          Math.ceil((volumeInMillimeter / 1e3) * 1e2) / 1e2
        } cm³`;
        break;
      case volumeInMillimeter >= 1e6 && volumeInMillimeter < 1e9:
        improvedValue = `${
          Math.ceil((volumeInMillimeter / 1e6) * 1e2) / 1e2
        } dm³`;
        break;
      case volumeInMillimeter >= 1e9:
        improvedValue = `${
          Math.ceil((volumeInMillimeter / 1e9) * 1e2) / 1e2
        } m³`;
        break;
      default:
        improvedValue = 'value';
      }
      return improvedValue;
    },
    close() {
      this.$emit('close');
    },
    validateQuantity(priceObj, index) {
      if(priceObj.quantity !== null && priceObj.quantity <= 0) {
        priceObj.quantityErrorMessages = this.$t('MustBeGreaterThan0');
        return false;
      }

      if(priceObj.quantity !== null && !Number.isInteger(Number(priceObj.quantity))) {
        priceObj.quantityErrorMessages = this.$t('MustBeInteger');
        return false;
      }

      if(priceObj.quantity !== null && this.priceRanges.some((p, pIdx) => {return index !== pIdx && Number(p.quantity) === Number(priceObj.quantity);})) {
        priceObj.quantityErrorMessages = this.$t('DuplicatedQuantity');
        return false;
      }

      priceObj.quantityErrorMessages = null;
      return true;
    },
    validateQuantities() {
      const textfieldQuantityRefs = Array.isArray(this.$refs.textFieldQuantityRef) ? this.$refs.textFieldQuantityRef : [this.$refs.textFieldQuantityRef];
      for(const textfield of textfieldQuantityRefs) {
        textfield.validate();
      }
    },
    validatePrice(priceObj) {
      if(priceObj.price !== null && priceObj.price < 0) {
        priceObj.priceErrorMessages = this.$t('MustBeGreaterOrEqualThan0');
        return false;
      }

      const invalidPriceIndex = this.hiddenSortedPriceRanges.findIndex((p, index) => {          
        return index !== 0 && p.price === priceObj.price && p.quantity === priceObj.quantity
          && priceObj.quantity > this.hiddenSortedPriceRanges[index - 1].quantity && Number(priceObj.price) > Number(this.hiddenSortedPriceRanges[index - 1].price);
      });

      if(invalidPriceIndex !== -1) {
        priceObj.priceErrorMessages = this.$t('ShouldBeLessOrEqualThan', {price: this.hiddenSortedPriceRanges[invalidPriceIndex - 1].price});
        return false;
      }

      priceObj.priceErrorMessages = null;
      return true;
    },
    validatePrices() {
      if(this.$refs.textFieldPriceRef) {
        const textfieldPricesArray = Array.isArray(this.$refs.textFieldPriceRef) ? this.$refs.textFieldPriceRef : [this.$refs.textFieldPriceRef];
        for(const textField of textfieldPricesArray) {
          textField.validate();
        }
      }
    },
    updateSortedPriceRanges() {
      this.hiddenSortedPriceRanges = [...this.priceRanges].sort((obj1, obj2) => {
        if(Number(obj1.quantity) === Number(obj2.quantity)) {
          if(Number(obj1.price === obj2.price)) {
            return 0;
          }
          if(Number(obj1.price < obj2.price)) {
            return -1;
          }
          return 1;
        }
        if(Number(obj1.quantity) < Number(obj2.quantity)) {
          return -1;
        }
        return 1;
      });
      
      this.validatePrices();
    },
    initNewLinePriceObject() {
      this.newLinePriceObject = {
        quantity: null,
        quantityTo: null,
        price: null,
        computedWithFormula: true
      };
    },
    initPriceRanges() {
      this.priceRanges = [];
      this.requiresInit = false;
      if(this.part.homologation?.prices?.length > 0) {
        this.percentage = Number(this.part.homologation.priceMultiplierPercentage ?? 100);
        for(const price of this.part.homologation.prices) {
          if(this.hasPriceAlgorithm) {
            const computedPrice = new BigNumber(FormulaPricing.getPrice(this.priceAlgorithm, this.part, price.quantity)).times(new BigNumber(this.percentage).dividedBy(100)).decimalPlaces(2,5);
            this.priceRanges.push({
              quantity: price.quantity,
              price: price.purchasePrice,
              computedWithFormula: new BigNumber(price.purchasePrice).isEqualTo(computedPrice),
              quantityErrorMessages: null,
              priceErrorMessages: null,
            });
          } else {
            this.priceRanges.push({
              quantity: price.quantity,
              price: price.purchasePrice,
              computedWithFormula: false,
              quantityErrorMessages: null,
              priceErrorMessages: null,
            });
          }
        }
      } else {
        this.quantities = [1, 2, 3, 4, 5, 10, 20, 50, 100];
        this.percentage = Number(this.part.homologation.priceMultiplierPercentage ?? 100);
        if(this.part.dna?.quantityPerOrder > 0 && !this.quantities.includes(this.part.dna.quantityPerOrder)) {
          this.quantities.push(this.part.dna.quantityPerOrder);
          this.quantities.sort((a, b) => {
            return a === b ? 0 : a < b ? -1 : 1;
          });
        }
        for(const quantity of this.quantities) {
          if(this.hasPriceAlgorithm) {
            const computedPrice = new BigNumber(FormulaPricing.getPrice(this.priceAlgorithm, this.part, quantity)).times(new BigNumber(this.percentage).dividedBy(100));
            this.priceRanges.push({
              quantity: quantity,
              price: computedPrice.decimalPlaces(2, 4).toNumber(), // 2 decimal places rounding down
              computedWithFormula: true,
              quantityErrorMessages: null,
              priceErrorMessages: null,
            });
          } else {
            this.priceRanges.push({
              quantity: quantity,
              price: null,
              computedWithFormula: false,
              quantityErrorMessages: null,
              priceErrorMessages: null,
            });
          }
        }
      }
      this.sortPriceRanges();
    },
    resetPriceRangesPrices() {
      for(const priceRange of this.priceRanges) {
        this.resetPriceObjPrice(priceRange, false);
      }
      this.updateSortedPriceRanges();
    },
    resetPriceObjPrice(priceObj, sort = true) {
      priceObj.price = new BigNumber(FormulaPricing.getPrice(this.priceAlgorithm, this.part, Math.floor(priceObj.quantity))).times(new BigNumber(this.percentage).dividedBy(100)).decimalPlaces(2, 4).toNumber();
      priceObj.computedWithFormula = true;
      if(sort) {
        this.updateSortedPriceRanges();
      }
    },
    resetNewLine() {
      this.newLinePriceObject.quantity = null;
      this.newLinePriceObject.quantityTo = null;
      this.newLinePriceObject.price = null;
    },
    priceObjChanged(priceObj) {
      this.$refs.newLineUnitPriceRef.validate();
      this.$refs.newLineQuantityRef.validate();
    },
    priceObjQuantityChanged(priceObj, index) {
      this.priceObjChanged(priceObj);
      this.updateSortedPriceRanges();
      this.validateQuantities();
    },
    priceObjPriceChanged(priceObj) {
      this.priceObjChanged(priceObj);
      priceObj.computedWithFormula = false;
      this.updateSortedPriceRanges();
    },
    percentageChanged() {
      const now = Date.now();
      this.lastPercentageUpdate = now;
      setTimeout(() => {
        if(this.lastPercentageUpdate === now) {
          this.updatePriceRanges();
          this.updateSortedPriceRanges();
        }
      }, 150);
    },
    updatePriceRanges() {
      for(const priceRange of this.priceRanges) {
        if(priceRange.computedWithFormula) {
          priceRange.price = new BigNumber(FormulaPricing.getPrice(this.priceAlgorithm, this.part, priceRange.quantity)).times(new BigNumber(this.percentage).dividedBy(100)).decimalPlaces(2, 4).toNumber();
        }
      }
    },
    newLineQuantityChanged() {
      if(!this.newLinePriceObject.quantity || this.newLinePriceObject.quantity <= 0) {
        this.newLinePriceObject.quantityTo = null;
        this.newLinePriceObject.price = null;
      } else {
        const index = this.priceRanges.findIndex(priceObj => {return priceObj.quantity > this.newLinePriceObject.quantity;});
        if(index === -1) {
          this.newLinePriceObject.quantityTo = -1;
        } else {
          this.newLinePriceObject.quantityTo = this.priceRanges[index].quantity;
        }

        if(this.hasPriceAlgorithm && (!this.newLinePriceObject.price || this.newLinePriceObject.computedWithFormula)) {
          this.newLinePriceObject.computedWithFormula = true;
          this.newLinePriceObject.price = new BigNumber(FormulaPricing.getPrice(this.priceAlgorithm, this.part, Math.floor(this.newLinePriceObject.quantity))).times(new BigNumber(this.percentage).dividedBy(100)).decimalPlaces(2, 4).toNumber();
        }
      }
    },
    newLinePriceChanged() {
      this.newLinePriceObject.computedWithFormula = false;
    },
    verifyInteger(event) {
      if(event.charCode < 48 || event.charCode > 57) {
        event.preventDefault();
      }
    },
    sortPriceRanges() {
      this.priceRanges.sort((obj1, obj2) => {
        if(Number(obj1.quantity) === Number(obj2.quantity)) {
          if(Number(obj1.price === obj2.price)) {
            return 0;
          }
          if(Number(obj1.price < obj2.price)) {
            return -1;
          }
          return 1;
        }
        if(Number(obj1.quantity) < Number(obj2.quantity)) {
          return -1;
        }
        return 1;
      });

      this.priceRanges = this.priceRanges.filter((obj, index) => {
        if(index === 0) {
          return true;
        }

        return obj.price === null || (Number(obj.price) !== Number(this.priceRanges[index - 1].price));
      });

      this.validatePrices();
    },
    removePriceRange(index) {
      if(index > 0) {
        this.priceRanges.splice(index, 1);
        this.updateSortedPriceRanges();
      }
    },
    addNewLineObject() {
      if(Math.floor(this.newLinePriceObject.quantity) > 1) {
        this.priceRanges.push({
          quantity: Math.floor(this.newLinePriceObject.quantity),
          price: new BigNumber(this.newLinePriceObject.price).decimalPlaces(2, 4).toNumber(),
          computedWithFormula: this.newLinePriceObject.computedWithFormula,
          quantityErrorMessages: null,
          priceErrorMessages: null,
        }),

        this.initNewLinePriceObject();
        this.sortPriceRanges();
        this.updateSortedPriceRanges();
      }
    },
    stopLoading() {
      this.loading = false;
    },
    savePrices() {
      const prices = [];
      for(const priceRange of this.priceRanges) {
        prices.push({
          quantity: Math.floor(priceRange.quantity),
          purchasePrice: new BigNumber(priceRange.price).decimalPlaces(2, 4).toNumber()
        });
      }
      this.loading = true;
      this.requiresInit = true;
      this.$emit('savePrices', prices, Math.floor(this.percentage));
    },
    priceTextfieldTabHandler(event, index) {
      /**
       * When pressing tab on a price textfield, it will focus the next price textfield
       */
      if(this.priceRanges.length > 1) {
        event.preventDefault();
        if(index < (this.priceRanges.length - 1)) {
          document.getElementById(`textFieldPriceId_${index + 1}`).focus();
        } else {
          document.getElementById(`textFieldPriceId_${0}`).focus();
        }
      }
    }
  },
};
</script>

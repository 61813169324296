var render = function () {
  var _vm$part$homologation;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "component-partHomologationValidation"
  }, [_vm.part.status === 'NEW' ? _c('v-alert', {
    attrs: {
      "color": "orange",
      "dense": "",
      "outlined": "",
      "text": "",
      "type": "warning"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('featuresDisabledDueToNewPart')) + " ")]) : _vm._e(), _c('v-row', {
    staticClass: "ma-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('CardFileValidation', {
    attrs: {
      "part": _vm.part,
      "download-part-file-object": _vm.downloadPartFileObject
    },
    on: {
      "selectExtension": _vm.selectExtension
    }
  })], 1), _vm.part.modifications ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('CardModificationsValidation', {
    attrs: {
      "part": _vm.part,
      "modifications-tool-enabled": _vm.modificationsToolEnabled
    },
    on: {
      "activateModificationsTool": _vm.activateModificationsTool
    }
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('CardOrientationValidation', {
    attrs: {
      "part": _vm.part,
      "orientation-tool-enabled": _vm.orientationToolEnabled
    },
    on: {
      "activateReorientationTool": _vm.activateReorientationTool
    }
  })], 1), _vm.part.serialNumberSettings ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('CardSerialNumberValidation', {
    attrs: {
      "part": _vm.part,
      "serial-number-tool-enabled": _vm.serialNumberToolEnabled
    },
    on: {
      "activateSerialNumberTool": _vm.activateSerialNumberTool
    }
  })], 1) : _vm._e(), _vm.part.criticalDimensions.length >= 1 ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('CardCriticalDimensionsValidation', {
    attrs: {
      "part": _vm.part,
      "critical-dimensions-tool-enabled": _vm.criticalDimensionsToolEnabled
    },
    on: {
      "activateCriticalDimensionsTool": _vm.activateCriticalDimensionsTool
    }
  })], 1) : _vm._e(), _vm.hasMultiplesObjects ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('CardMultipleObjectsValidation', {
    attrs: {
      "part": _vm.part
    },
    on: {
      "activateObjectsSplitterTool": _vm.activateObjectsSplitterTool
    }
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('CardManufacturingParametersValidation', {
    attrs: {
      "part": _vm.part,
      "disable-card-highlight": _vm.disableCardHighlight || ((_vm$part$homologation = _vm.part.homologation) === null || _vm$part$homologation === void 0 ? void 0 : _vm$part$homologation.status) !== 'SUPPLIER_QUOTATION'
    },
    on: {
      "openManufacturingParametersDialog": _vm.openManufacturingParametersDialog
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('CardAttachments', {
    attrs: {
      "part": _vm.part
    },
    on: {
      "openAttachments": _vm.openAttachments
    }
  })], 1), _vm.billingEnabled ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('CardPricesValidation', {
    attrs: {
      "part": _vm.part,
      "disable-card-highlight": _vm.disableCardHighlight
    },
    on: {
      "openPricesDialog": _vm.openPricesDialog
    }
  })], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template lang="html" src="./cardFileValidation.template.vue"></template>

<style lang="scss" src="./cardFileValidation.scss"></style>

<script>

import { ProgressSingle } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./cardFileValidation.i18n');
export default {
  name: 'CardFileValidation',
  components: {
    ProgressSingle
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  props: {
    part: {
      type: Object,
      required: true
    },
    downloadPartFileObject: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
    };
  },
  computed: {
    hasError() {
      return !this.part?.analysisInformation?.errorsInformation || Object.values(this.part.analysisInformation.errorsInformation).some((v, index) => {return index !== 0 && v;});
    },
    partErrors() {
      return this.part?.analysisInformation?.errorsInformation ? Object.keys(this.part.analysisInformation.errorsInformation).filter((v, index) => {return index !== 0 && this.part.analysisInformation.errorsInformation[v];}) : [];
    },
    downloading() {
      return !!this.downloadPartFileObject && this.downloadPartFileObject.status !== 'done';
    }
  },
  created() {
  },
  methods: {
    selectExtension() {
      this.$emit('selectExtension');
    },
  },
};
</script>

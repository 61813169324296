var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hover = _ref.hover;
        return [_c('v-card', {
          staticClass: "component-cardFileValidation",
          attrs: {
            "width": "200px",
            "color": hover ? 'hover' : '',
            "disabled": _vm.part.status === 'NEW' && _vm.part.serialNumberSettings && !_vm.part.homologation.serialNumber
          },
          on: {
            "click": _vm.selectExtension
          }
        }, [_c('v-tooltip', {
          attrs: {
            "value": hover,
            "right": "",
            "content-class": "component-cardFileValidation tooltip-hover",
            "offset-overflow": "",
            "disabled": !_vm.hasError
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('div', _vm._g({}, on), [_c('v-card-title', {
                staticClass: "pt-1 pb-1 d-flex justify-space-between px-2"
              }, [!_vm.hasError ? _c('v-icon', {
                attrs: {
                  "small": "",
                  "color": "success"
                }
              }, [_vm._v(" fas fa-check-circle ")]) : _c('v-icon', {
                attrs: {
                  "small": "",
                  "color": "warning"
                }
              }, [_vm._v(" fas fa-exclamation-triangle ")]), _c('span', {
                staticClass: "text-subtitle-1"
              }, [_vm._v(" " + _vm._s(_vm.$t('3dFile')) + " ")]), _c('v-icon', {
                attrs: {
                  "small": "",
                  "color": "transparent"
                }
              }, [_vm._v(" fas fa-check-circle ")])], 1), _c('v-card-text', {
                staticClass: "py-0 px-2 text-center"
              }, [!_vm.downloading ? _c('v-img', {
                attrs: {
                  "src": require("../assets/3d-file-2.png"),
                  "width": "100%",
                  "height": "128px",
                  "contain": "",
                  "transition": "fade-transition"
                }
              }) : _vm._e(), _c('div', {
                directives: [{
                  name: "show",
                  rawName: "v-show",
                  value: _vm.downloading,
                  expression: "downloading"
                }],
                staticClass: "div-progressSingle",
                attrs: {
                  "transition": "fade-transition"
                }
              }, [_c('ProgressSingle', {
                staticClass: "text-h6 py-2",
                attrs: {
                  "value": !_vm.downloadPartFileObject ? 0 : _vm.downloadPartFileObject.progress,
                  "indeterminate": _vm.downloadPartFileObject && _vm.downloadPartFileObject.progress === 100 && _vm.downloadPartFileObject.status === 'progress',
                  "size": 112,
                  "width": 5,
                  "display-image": false
                }
              })], 1)], 1), _c('v-card-actions', {
                staticClass: "pt-1 pb-2 justify-center px-2"
              }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('Download')) + " ")]), _c('v-icon', {
                staticClass: "ml-2",
                attrs: {
                  "small": "",
                  "color": "info"
                }
              }, [_vm._v(" fas fa-download ")])], 1)], 1)];
            }
          }], null, true)
        }, [_c('v-card', {
          attrs: {
            "max-width": "350px"
          }
        }, [_c('v-card-text', [_c('p', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('ErrorsHaveBeenDetected')) + ": ")]), _c('v-list', {
          attrs: {
            "dense": ""
          }
        }, _vm._l(_vm.partErrors, function (error, index) {
          return _c('v-list-item', {
            key: index
          }, [_c('v-list-item-icon', [_c('v-icon', {
            attrs: {
              "x-small": "",
              "color": "info"
            }
          }, [_vm._v(" fas fa-circle ")])], 1), _c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t(error)) + " ")])], 1);
        }), 1)], 1)], 1)], 1)], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }
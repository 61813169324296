<template lang="html" src="./partHomologationValidation.template.vue"></template>

<style lang="scss" src="./partHomologationValidation.scss"></style>

<script>

import CardFileValidation from './cardFileValidation/CardFileValidation';
import CardModificationsValidation from './cardModificationsValidation/CardModificationsValidation';
import CardCriticalDimensionsValidation from './cardCriticalDimensionsValidation/CardCriticalDimensionsValidation';
import CardMultipleObjectsValidation from './cardMultipleObjectsValidation/CardMultipleObjectsValidation';
import CardSerialNumberValidation from './cardSerialNumberValidation/CardSerialNumberValidation';
import CardOrientationValidation from './cardOrientationValidation/CardOrientationValidation';
import CardManufacturingParametersValidation from './cardManufacturingParametersValidation/CardManufacturingParametersValidation';
import CardPricesValidation from './cardPricesValidation/CardPricesValidation';
import CardAttachments from './cardAttachments/CardAttachments';

const i18nData = require('./partHomologationValidation.i18n');
export default {
  name: 'PartHomologationValidation',
  components: {
    CardFileValidation,
    CardCriticalDimensionsValidation,
    CardMultipleObjectsValidation,
    CardOrientationValidation,
    CardSerialNumberValidation,
    CardManufacturingParametersValidation,
    CardPricesValidation,
    CardModificationsValidation,
    CardAttachments
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  props: {
    part: {
      type: Object,
      required: true
    },
    modificationsToolEnabled: {
      type: Boolean,
      required: true
    },
    orientationToolEnabled: {
      type: Boolean,
      required: true
    },
    serialNumberToolEnabled: {
      type: Boolean,
      required: true
    },
    criticalDimensionsToolEnabled: {
      type: Boolean,
      required: true
    },
    downloadPartFileObject: {
      type: Object,
      default: null
    },
    disableCardHighlight: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    };
  },
  computed: {
    hasMultiplesObjects() {
      return this.part?.analysisInformation?.outerShells >= 2 || this.part?.analysisInformation?.innerShells >= 1;
    },
    billingEnabled() {
      if(this.part.homologation?.public) {
        return true;
      }

      const privateWarehouse = this.$appConfig.privateWarehousesArray.find(pW => {
        return pW._id === this.part.brand_id;
      });

      if(!privateWarehouse || privateWarehouse.billing) {
        return true;
      }

      return false;
    }
  },
  created() {
   
  },
  methods: {
    selectExtension() {
      this.$emit('selectExtension');
    },
    activateModificationsTool() {
      this.$emit('activateModificationsTool');
    },
    activateReorientationTool() {
      this.$emit('activateReorientationTool');
    },
    activateSerialNumberTool() {
      this.$emit('activateSerialNumberTool');
    },
    activateCriticalDimensionsTool() {
      this.$emit('activateCriticalDimensionsTool');
    },
    activateObjectsSplitterTool() {
      this.$emit('activateObjectsSplitterTool');
    },
    openManufacturingParametersDialog() {
      this.$emit('openManufacturingParametersDialog');
    },
    openPricesDialog() {
      this.$emit('openPricesDialog');
    },
    openAttachments() {
      this.$emit('openAttachments');
    }
  },
};
</script>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hover = _ref.hover;
        return [_c('v-card', {
          staticClass: "component-cardSerialNumberValidation",
          class: {
            'isSelected': _vm.serialNumberToolEnabled
          },
          attrs: {
            "width": "200px",
            "color": hover ? 'hover' : ''
          },
          on: {
            "click": _vm.activateSerialNumberTool
          }
        }, [_c('v-tooltip', {
          attrs: {
            "right": "",
            "disabled": true
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('div', _vm._g({}, on), [_c('v-card-title', {
                staticClass: "pt-1 pb-1 d-flex justify-space-between px-2"
              }, [_c('v-icon', {
                attrs: {
                  "small": "",
                  "color": "info"
                }
              }, [_vm._v(" fas fa-info-circle ")]), _c('span', {
                staticClass: "text-subtitle-1"
              }, [_vm._v(" " + _vm._s(_vm.$t('SerialNumber')) + " ")]), _c('v-icon', {
                attrs: {
                  "small": "",
                  "color": "transparent"
                }
              }, [_vm._v(" fas fa-info-circle ")])], 1), _c('v-card-text', {
                staticClass: "py-0 px-2"
              }, [_c('v-img', {
                attrs: {
                  "src": require("../assets/serialNumber_2.png"),
                  "width": "100%",
                  "height": "128px",
                  "contain": ""
                }
              })], 1), _c('v-card-actions', {
                staticClass: "pt-1 pb-2 justify-center px-2"
              }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('Display')) + " ")]), _c('v-icon', {
                staticClass: "ml-1",
                attrs: {
                  "small": "",
                  "color": "info"
                }
              }, [_vm._v(" fas fa-eye ")])], 1)], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" test ")])])], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }
<template lang="html" src="./cardOrientationValidation.template.vue"></template>

<style lang="scss" src="./cardOrientationValidation.scss"></style>

<script>

const i18nData = require('./cardOrientationValidation.i18n');
export default {
  name: 'CardOrientationValidation',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  props: {
    part: {
      type: Object,
      required: true
    },
    orientationToolEnabled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
    };
  },
  created() {
   
  },
  methods: {
    activateReorientationTool() {
      this.$emit('activateReorientationTool');
    },
  },
};
</script>

<template lang="html" src="./validationProblemDialog.template.vue"></template>

<style lang="scss" src="./validationProblemDialog.scss"></style>

<script>
const i18nData = require('./validationProblemDialog.i18n');

export default {
  name: 'ValidationProblemDialog',
  props: {
    part: {
      type: Object,
      required: true
    },
    technologyOrMaterialRequired: {
      type: Boolean,
      default: false
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      technology: '',
      material: '',
      subjectMessage: '',
      dialog: false,
      loading: false,
      rulesRequired: [(value) => !!value || this.$t('Required')],
    };
  },
  methods: {
    sendTicket() {
      this.loading = true;
      this.$emit('sendTicket', this.subjectMessage, this.technology, this.material);
    },
    openDialog() {
      this.technology = '';
      this.material = '';
      this.subjectMessage = '';
      this.dialog = true;
      this.loading = false;
    },
    closeDialog() {
      this.dialog = false;
      this.$emit('close');
    }
  },
};
</script>

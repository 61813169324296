<template lang="html" src="./cardModificationsValidation.template.vue"></template>

<style lang="scss" src="./cardModificationsValidation.scss"></style>

<script>

const i18nData = require('./cardModificationsValidation.i18n');
export default {
  name: 'CardModificationsValidation',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  props: {
    part: {
      type: Object,
      required: true
    },
    modificationsToolEnabled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  created() {
    
  },
  methods: {
    activateModificationsTool() {
      this.$emit('activateModificationsTool');
    },
  },
};
</script>

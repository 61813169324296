var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-validationProblemDialog"
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "600",
      "content-class": "module-validationProblemDialog"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm.technologyOrMaterialRequired ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('ModifyPartTechnologyOrMaterial')) + " ")]) : _c('span', [_vm._v(_vm._s(_vm.$t('ModifyPart')))])]), _c('v-divider'), _c('v-card-text', [_c('v-form', {
    ref: "supportEmailForm",
    staticClass: "mt-6"
  }, [_vm.technologyOrMaterialRequired ? _c('p', [_vm._v(" " + _vm._s(_vm.$t('CurrentlyInDNA')) + " ")]) : _vm._e(), _vm.technologyOrMaterialRequired ? _c('v-row', [_c('v-col', {
    staticClass: "col-6"
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.part.dna.technology,
      "disabled": "",
      "label": _vm.$t('Technology'),
      "outlined": ""
    }
  })], 1), _c('v-col', {
    staticClass: "col-6"
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.$t(_vm.part.dna.material),
      "disabled": "",
      "label": _vm.$t('Material'),
      "outlined": ""
    }
  })], 1)], 1) : _vm._e(), _vm.technologyOrMaterialRequired ? _c('v-divider', {
    staticClass: "mb-4"
  }) : _vm._e(), _vm.technologyOrMaterialRequired ? _c('p', [_vm._v(" " + _vm._s(_vm.$t('IndicateTechnologyAndMaterialToUseText')) + " ")]) : _vm._e(), _vm.technologyOrMaterialRequired ? _c('v-row', [_c('v-col', {
    staticClass: "col-6"
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('Technology'),
      "rules": _vm.rulesRequired,
      "outlined": ""
    },
    model: {
      value: _vm.technology,
      callback: function callback($$v) {
        _vm.technology = $$v;
      },
      expression: "technology"
    }
  })], 1), _c('v-col', {
    staticClass: "col-6"
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('Material'),
      "rules": _vm.rulesRequired,
      "outlined": ""
    },
    model: {
      value: _vm.material,
      callback: function callback($$v) {
        _vm.material = $$v;
      },
      expression: "material"
    }
  })], 1)], 1) : _vm._e(), _vm.technologyOrMaterialRequired ? _c('p', [_vm._v(" " + _vm._s(_vm.$t('OptionalInformationText')) + " ")]) : _c('p', [_vm._v(" " + _vm._s(_vm.$t('DescriptionRequiredText')) + " ")]), _c('v-textarea', {
    attrs: {
      "label": _vm.$t('Description'),
      "outlined": "",
      "auto-grow": true,
      "rules": !_vm.technologyOrMaterialRequired ? _vm.rulesRequired : []
    },
    model: {
      value: _vm.subjectMessage,
      callback: function callback($$v) {
        _vm.subjectMessage = $$v;
      },
      expression: "subjectMessage"
    }
  })], 1), _c('p', {
    staticClass: "px-1 text-body-2 font-weight-light"
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-info-circle ")]), _vm._v(" " + _vm._s(_vm.$t('HomologationSuspensionMessage')) + " ")], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none ml-5",
    attrs: {
      "color": "info",
      "disabled": _vm.technologyOrMaterialRequired && (!_vm.technology || !_vm.material) || !_vm.technologyOrMaterialRequired && !_vm.subjectMessage
    },
    on: {
      "click": _vm.sendTicket
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-paper-plane ")]), _vm._v(" " + _vm._s(_vm.$t('LockAndSend')) + " ")], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template lang="html" src="./cardCriticalDimensionsValidation.template.vue"></template>

<style lang="scss" src="./cardCriticalDimensionsValidation.scss"></style>

<script>

const i18nData = require('./cardCriticalDimensionsValidation.i18n');
export default {
  name: 'CardCriticalDimensionsValidation',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  props: {
    part: {
      type: Object,
      required: true
    },
    criticalDimensionsToolEnabled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
    };
  },
  created() {
   
  },
  methods: {
    activateCriticalDimensionsTool() {
      this.$emit('activateCriticalDimensionsTool');
    }
  },
};
</script>

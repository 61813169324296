<template lang="html" src="./rejectHomologationDialog.template.vue"></template>

<style lang="scss" src="./rejectHomologationDialog.scss"></style>

<script>
const i18nData = require('./rejectHomologationDialog.i18n');

export default {
  name: 'RejectHomologationDialog',
  props: {
    part: {
      type: Object,
      required: true
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      subjects: [],
      previouslySelectedSubject: null,
      selectedSubject: null,
      message: '',
      rules: [
        (v) => v.length <= 2000 || `${v.length} / 2000`
      ]
    };
  },
  created() {
    this.subjects = [
      {
        value: 'unadaptedTechnologyOrMaterialOrFinishes',
        text: this.$t('unadaptedTechnologyOrMaterialOrFinishes')
      },
      {
        value: 'partDesignShouldBeModified',
        text: this.$t('partDesignShouldBeModified')
      },
      {
        value: 'partFileQualityIsInsufficient',
        text: this.$t('partFileQualityIsInsufficient')
      },
    ];

    if(this.part.criticalDimensions.length >= 1) {
      this.subjects.push(
        {
          value: 'unfeasibleCriticalDimensions',
          text: this.$t('unfeasibleCriticalDimensions')
        },
      );
    }

    if(this.part.serialNumberSettings) {
      this.subjects.push(
        {
          value: 'unadaptedSerialNumberParameters',
          text: this.$t('unadaptedSerialNumberParameters')
        },
      );
    }

    if(this.part.analysisInformation.outerShells >= 2 || this.part.analysisInformation.innerShells.length >= 1) {
      this.subjects.push(
        {
          value: 'multipleObjectsPresenceIsAnIssue',
          text: this.$t('multipleObjectsPresenceIsAnIssue')
        }
      );
    }

    this.subjects.push(
      {
        value: 'partHasIssues',
        text: `(${this.$t('Other')}) ${this.$t('partHasIssues')}`
      }
    );
  },
  methods: {
    sendTicket() {
      this.loading = true;
    },
    openDialog() {
      this.dialog = true;
      this.loading = false;
    },
    closeDialog() {
      this.dialog = false;
      this.loading = false;
      this.$emit('close');
    },
    rejectPartHomologation() {
      this.loading = true;

      this.$emit('rejectPartHomologation', this.$t(this.selectedSubject), this.message);
    },
    selectedSubjectChanged() {
      if(!this.message || this.$t(`${this.previouslySelectedSubject}Message`) === this.message) {
        this.message = this.$t(`${this.selectedSubject}Message`);
      }
      this.previouslySelectedSubject = this.selectedSubject;
    }
  },
};
</script>

var render = function () {
  var _vm$part, _vm$part$slicerAnalys, _vm$part2, _vm$part2$slicerAnaly, _vm$part3, _vm$part3$slicerAnaly, _vm$part4, _vm$part4$slicerAnaly, _vm$part5, _vm$part5$slicerAnaly;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    staticClass: "component-pricingRangesV2"
  }, 'v-card', _vm.$attrs, false), [_c('v-card-title', [_c('v-icon', {
    staticClass: "mr-4",
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" fas fa-euro-sign ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('Pricing')) + " ")]), _c('v-spacer'), _vm.displayCloseButton ? _c('v-btn', {
    attrs: {
      "color": "info"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', [_vm._v(" fas fa-times ")])], 1) : _vm._e()], 1), _vm.hasPriceAlgorithm || _vm.priceRanges.length > 0 ? _c('v-card-text', {
    staticClass: "pb-0"
  }, [_vm.part.homologation.technology === 'FFF' ? _c('v-alert', {
    attrs: {
      "color": "secondary",
      "dense": "",
      "outlined": "",
      "prominent": "",
      "type": "info"
    }
  }, [((_vm$part = _vm.part) === null || _vm$part === void 0 ? void 0 : (_vm$part$slicerAnalys = _vm$part.slicerAnalysis) === null || _vm$part$slicerAnalys === void 0 ? void 0 : _vm$part$slicerAnalys.part_volume) > 0 ? [_c('v-container', {
    attrs: {
      "no-gutter": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-list-item', {
    staticClass: "py-0"
  }, [_c('v-list-item-content', {
    staticClass: "py-0"
  }, [_c('v-list-item-title', [_vm._v(_vm._s(_vm.$t('parameters')))]), _c('v-list-item-subtitle', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('machine_nozzle_size')) + " ")]), _vm._v(_vm._s(_vm.part.slicerAnalysis.machine_nozzle_size) + " mm ")]), _c('v-list-item-subtitle', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('layer_height')) + " ")]), _vm._v(_vm._s(_vm.part.slicerAnalysis.layer_height) + " mm ")]), _c('v-list-item-subtitle', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('infill_sparse_density')) + " ")]), _vm._v(_vm._s(_vm.part.slicerAnalysis.infill_sparse_density) + "% ")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-list-item', {
    staticClass: "py-0"
  }, [_c('v-list-item-content', {
    staticClass: "py-0"
  }, [_c('v-list-item-title', [_vm._v(_vm._s(_vm.$t('print_time')))]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.part.slicerAnalysis.print_time) + " ")])], 1)], 1), _c('v-list-item', {
    staticClass: "py-0"
  }, [_c('v-list-item-content', {
    staticClass: "py-0"
  }, [_c('v-list-item-title', [_vm._v(_vm._s(_vm.$t('volume')))]), _c('v-list-item-subtitle', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('part_volume')) + " ")]), _vm._v(_vm._s(_vm.improveVolumeReadability(_vm.part.slicerAnalysis.part_volume)) + " ")]), _c('v-list-item-subtitle', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('support_volume')) + " ")]), _vm._v(_vm._s(_vm.improveVolumeReadability(_vm.part.slicerAnalysis.support_volume)) + " ")]), _c('v-list-item-subtitle', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('total_volume')) + " ")]), _vm._v(_vm._s(_vm.improveVolumeReadability(_vm.part.slicerAnalysis.total_volume)) + " ")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "shrink"
  }, [_c('v-btn', {
    staticClass: "text-none white--text my-2",
    attrs: {
      "color": "orange"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('editParameter');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('editParameters')) + " ")])], 1)], 1)], 1)] : ['WAITING', 'IN_PROGRESS'].includes(_vm.part.analysisStatus) ? [_vm._v(" " + _vm._s(_vm.$t('sliceInProgress')) + " ")] : [_vm._v(" " + _vm._s(_vm.$t('NeedToSlice')) + " "), _c('v-btn', {
    staticClass: "text-none white--text my-2",
    attrs: {
      "color": "orange"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('editParameter');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('editParameters')) + " ")])]], 2) : _vm._e(), _vm.part.homologation.technology === 'FFF' && _vm.algorithmVolumeVariableUsage.includes('volume') && _vm.algorithmVolumeVariableUsage.length === 1 ? _c('v-alert', {
    attrs: {
      "color": "orange",
      "dense": "",
      "outlined": "",
      "prominent": "",
      "type": "warning"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('yourAlgorithUseVolumeForFFF')) + " " + _vm._s(_vm.improveVolumeReadability(_vm.part.volume)) + " ")]) : _vm.part.homologation.technology === 'FFF' && _vm.algorithmVolumeVariableUsage.length > 1 ? _c('v-alert', {
    attrs: {
      "color": "orange",
      "dense": "",
      "outlined": "",
      "prominent": "",
      "type": "warning"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('yourAlgorithUseMixedContentIncludingStandardVolume')) + " "), _vm._l(_vm.algorithmVolumeVariableUsage, function (algo, index) {
    return [_vm._v(" " + _vm._s(index > 0 ? ', ' : '') + _vm._s(_vm.$t("".concat(algo, "_algo"))) + " ")];
  }), !((_vm$part2 = _vm.part) !== null && _vm$part2 !== void 0 && (_vm$part2$slicerAnaly = _vm$part2.slicerAnalysis) !== null && _vm$part2$slicerAnaly !== void 0 && _vm$part2$slicerAnaly.part_volume) || ((_vm$part3 = _vm.part) === null || _vm$part3 === void 0 ? void 0 : (_vm$part3$slicerAnaly = _vm$part3.slicerAnalysis) === null || _vm$part3$slicerAnaly === void 0 ? void 0 : _vm$part3$slicerAnaly.part_volume) === 0 ? [_c('p', {
    staticClass: "mt-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('fallbackVolumeUsage')) + " " + _vm._s(_vm.improveVolumeReadability(_vm.part.volume)) + " ")])] : _vm._e()], 2) : _vm.part.homologation.technology === 'FFF' && _vm.algorithmVolumeVariableUsage.length === 1 ? _c('v-alert', {
    attrs: {
      "color": "orange",
      "dense": "",
      "outlined": "",
      "prominent": "",
      "type": "warning"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('yourAlgorithUseSlicerVolume')) + " " + _vm._s(_vm.$t("".concat(_vm.algorithmVolumeVariableUsage[0], "_algo"))) + " "), !((_vm$part4 = _vm.part) !== null && _vm$part4 !== void 0 && (_vm$part4$slicerAnaly = _vm$part4.slicerAnalysis) !== null && _vm$part4$slicerAnaly !== void 0 && _vm$part4$slicerAnaly.part_volume) || ((_vm$part5 = _vm.part) === null || _vm$part5 === void 0 ? void 0 : (_vm$part5$slicerAnaly = _vm$part5.slicerAnalysis) === null || _vm$part5$slicerAnaly === void 0 ? void 0 : _vm$part5$slicerAnaly.part_volume) === 0 ? [_c('p', {
    staticClass: "mt-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('fallbackVolumeUsage')) + " " + _vm._s(_vm.improveVolumeReadability(_vm.part.volume)) + " ")])] : _vm._e()], 2) : _vm._e(), _c('v-row', [_c('v-col', {
    staticClass: "d-flex align-center py-0 text-subtitle-2",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('FastModification')) + " ")]), _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-icon', _vm._g({
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "color": "info"
          }
        }, on), [_vm._v(" fas fa-info-circle ")])];
      }
    }], null, false, 2156687203)
  }, [_c('div', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('OnlyAppliesToRangesComputedWithTheAlgorithm')) + " ")]), _c('v-icon', {
    staticClass: "ml-2",
    attrs: {
      "small": "",
      "color": "white"
    }
  }, [_vm._v(" fas fa-calculator ")])], 1)])], 1), _c('v-col', {
    staticClass: "d-flex align-center py-0"
  }, [_c('v-slider', {
    attrs: {
      "disabled": !_vm.editMode,
      "hide-details": "auto",
      "min": "25",
      "max": "300",
      "step": "1",
      "type": "number"
    },
    on: {
      "input": _vm.percentageChanged
    },
    model: {
      value: _vm.percentage,
      callback: function callback($$v) {
        _vm.percentage = $$v;
      },
      expression: "percentage"
    }
  })], 1), _c('v-col', {
    staticClass: "d-flex align-center py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-text-field', {
    staticClass: "text-field-percentage",
    attrs: {
      "disabled": !_vm.editMode,
      "append-icon": "fas fa-percentage",
      "type": "number",
      "min": "25",
      "max": "300",
      "step": "any"
    },
    on: {
      "input": _vm.percentageChanged
    },
    model: {
      value: _vm.percentage,
      callback: function callback($$v) {
        _vm.percentage = $$v;
      },
      expression: "percentage"
    }
  })], 1)], 1), _c('v-simple-table', {
    staticClass: "main-table",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('thead', [_c('tr', [_c('th', [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-cubes ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('From')) + " ")])], 1), _c('th', [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-cubes ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('To')) + " ")])], 1), _c('th', [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-euro-sign ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('UnitPrice')) + " ")])], 1), _c('th', {
          staticClass: "text-center"
        }, [_vm.hasPriceAlgorithm ? _c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "disabled": !_vm.editMode,
                  "color": "info",
                  "x-small": "",
                  "text": "",
                  "fab": ""
                },
                on: {
                  "click": _vm.resetPriceRangesPrices
                }
              }, on), [_c('v-icon', [_vm._v(" fas fa-redo ")])], 1)];
            }
          }], null, false, 1226659523)
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('RecomputePricesWithAlgorithm')) + " ")])]) : _vm._e()], 1)])]), _c('tbody', [_vm._l(_vm.priceRanges, function (priceObj, index) {
          return _c('v-hover', {
            key: index,
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref3) {
                var _vm$part$dna;

                var hover = _ref3.hover;
                return [_c('tr', [_c('td', [_c('v-text-field', {
                  ref: "textFieldQuantityRef",
                  refInFor: true,
                  staticClass: "text-field-qt-from my-1",
                  class: {
                    'custom-margin-error-textfield': !!priceObj.priceErrorMessages || !!priceObj.quantityErrorMessages
                  },
                  attrs: {
                    "disabled": !_vm.editMode || index === 0,
                    "rules": [_vm.validateQuantity(priceObj, index)],
                    "dense": "",
                    "outlined": "",
                    "hide-details": "auto",
                    "type": "number",
                    "min": "1",
                    "error-messages": priceObj.quantityErrorMessages
                  },
                  on: {
                    "keypress": _vm.verifyInteger,
                    "input": function input($event) {
                      return _vm.priceObjQuantityChanged(priceObj, index);
                    }
                  },
                  model: {
                    value: priceObj.quantity,
                    callback: function callback($$v) {
                      _vm.$set(priceObj, "quantity", $$v);
                    },
                    expression: "priceObj.quantity"
                  }
                })], 1), _c('td', [index < _vm.priceRanges.length - 1 ? _c('v-text-field', {
                  staticClass: "text-field-qt-to",
                  attrs: {
                    "value": Math.max(priceObj.quantity, _vm.priceRanges[index + 1].quantity - 1),
                    "dense": "",
                    "hide-details": "auto",
                    "disabled": "",
                    "type": "number"
                  }
                }) : _c('v-icon', {
                  attrs: {
                    "small": "",
                    "color": "disabled"
                  }
                }, [_vm._v(" fas fa-infinity ")])], 1), _c('td', [_c('v-row', [_c('v-col', {
                  attrs: {
                    "cols": "auto"
                  }
                }, [_c('v-text-field', {
                  ref: "textFieldPriceRef",
                  refInFor: true,
                  staticClass: "text-field-price my-1",
                  class: {
                    'custom-margin-error-textfield': !!priceObj.priceErrorMessages || !!priceObj.quantityErrorMessages
                  },
                  attrs: {
                    "id": "textFieldPriceId_".concat(index),
                    "disabled": !_vm.editMode,
                    "dense": "",
                    "outlined": "",
                    "hide-details": "auto",
                    "type": "number",
                    "step": "any",
                    "rules": [_vm.validatePrice(priceObj)],
                    "error-messages": priceObj.priceErrorMessages
                  },
                  on: {
                    "input": function input($event) {
                      return _vm.priceObjPriceChanged(priceObj);
                    },
                    "keydown": function keydown($event) {
                      if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")) { return null; }
                      return _vm.priceTextfieldTabHandler($event, index);
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "append",
                    fn: function fn() {
                      return [_c('v-icon', {
                        attrs: {
                          "color": "info"
                        }
                      }, [_vm._v(" fas fa-euro-sign ")])];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: priceObj.price,
                    callback: function callback($$v) {
                      _vm.$set(priceObj, "price", $$v);
                    },
                    expression: "priceObj.price"
                  }
                })], 1), _c('v-col', {
                  attrs: {
                    "cols": "auto",
                    "align-self": "center"
                  }
                }, [_vm.hasPriceAlgorithm && priceObj.computedWithFormula ? _c('v-tooltip', {
                  attrs: {
                    "top": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "activator",
                    fn: function fn(_ref4) {
                      var on = _ref4.on;
                      return [_c('v-icon', _vm._g({
                        staticClass: "mx-2",
                        class: {
                          'half-transparent': !hover
                        },
                        attrs: {
                          "color": "info",
                          "small": ""
                        }
                      }, on), [_vm._v(" fas fa-calculator ")])];
                    }
                  }], null, true)
                }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('PriceComputedWithYourAlgorithm')) + " ")])]) : _vm._e(), Number(priceObj.quantity) === Number((_vm$part$dna = _vm.part.dna) === null || _vm$part$dna === void 0 ? void 0 : _vm$part$dna.quantityPerOrder) ? _c('v-tooltip', {
                  attrs: {
                    "top": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "activator",
                    fn: function fn(_ref5) {
                      var on = _ref5.on;
                      return [_c('v-icon', _vm._g({
                        staticClass: "mx-2",
                        class: {
                          'half-transparent': !hover
                        },
                        attrs: {
                          "color": "orange",
                          "small": ""
                        }
                      }, on), [_vm._v(" fas fa-dna ")])];
                    }
                  }], null, true)
                }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('DnaQuantity')) + " ")])]) : _vm._e()], 1)], 1)], 1), _c('td', {
                  staticClass: "text-center"
                }, [index !== 0 ? _c('v-tooltip', {
                  attrs: {
                    "top": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "activator",
                    fn: function fn(_ref6) {
                      var on = _ref6.on;
                      return [_c('v-btn', _vm._g({
                        class: {
                          'half-transparent': !hover
                        },
                        attrs: {
                          "disabled": !_vm.editMode,
                          "fab": "",
                          "x-small": "",
                          "text": "",
                          "rounded": "",
                          "color": "error"
                        },
                        on: {
                          "click": function click($event) {
                            return _vm.removePriceRange(index);
                          }
                        }
                      }, on), [_c('v-icon', [_vm._v(" fas fa-times ")])], 1)];
                    }
                  }], null, true)
                }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('RemoveThisPriceRange')) + " ")])]) : _vm._e(), _vm.hasPriceAlgorithm ? _c('v-tooltip', {
                  attrs: {
                    "top": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "activator",
                    fn: function fn(_ref7) {
                      var on = _ref7.on;
                      return [_c('v-btn', _vm._g({
                        class: {
                          'half-transparent': !hover
                        },
                        attrs: {
                          "disabled": !_vm.editMode,
                          "fab": "",
                          "x-small": "",
                          "text": "",
                          "rounded": "",
                          "color": "info"
                        },
                        on: {
                          "click": function click($event) {
                            return _vm.resetPriceObjPrice(priceObj);
                          }
                        }
                      }, on), [_c('v-icon', {
                        attrs: {
                          "small": ""
                        }
                      }, [_vm._v(" fas fa-redo ")])], 1)];
                    }
                  }], null, true)
                }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('RecomputeThisPriceWithAlgorithm')) + " ")])]) : _vm._e()], 1)])];
              }
            }], null, true)
          });
        }), _vm.editMode ? _c('tr', {
          staticClass: "no-pointer-events row-empty"
        }, [_c('td', {
          staticClass: "col-empty text-caption black--text",
          attrs: {
            "colspan": "4"
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "small": "",
            "color": "info"
          }
        }, [_vm._v(" fas fa-arrow-alt-circle-down ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('NewPriceLine')) + " ")])], 1)]) : _vm._e(), _vm.editMode && _vm.newLinePriceObject ? _c('v-hover', {
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref8) {
              var hover = _ref8.hover;
              return [_c('tr', [_c('td', [_c('v-text-field', {
                ref: "newLineQuantityRef",
                staticClass: "text-field-qt-from my-1",
                attrs: {
                  "dense": "",
                  "outlined": "",
                  "hide-details": "auto",
                  "type": "number",
                  "min": "1",
                  "rules": _vm.rulesNewLineQuantity
                },
                on: {
                  "input": _vm.newLineQuantityChanged,
                  "keypress": function keypress($event) {
                    return _vm.verifyInteger($event);
                  }
                },
                model: {
                  value: _vm.newLinePriceObject.quantity,
                  callback: function callback($$v) {
                    _vm.$set(_vm.newLinePriceObject, "quantity", $$v);
                  },
                  expression: "newLinePriceObject.quantity"
                }
              })], 1), _c('td', [_vm.newLinePriceObject.quantityTo !== -1 ? _c('v-text-field', {
                staticClass: "text-field-qt-to",
                attrs: {
                  "value": _vm.newLinePriceObject.quantityTo,
                  "dense": "",
                  "hide-details": "auto",
                  "disabled": "",
                  "type": "number"
                }
              }) : _c('v-icon', {
                attrs: {
                  "small": "",
                  "color": "disabled"
                }
              }, [_vm._v(" fas fa-infinity ")])], 1), _c('td', [_c('v-row', [_c('v-col', {
                attrs: {
                  "cols": "auto",
                  "align-self": "center"
                }
              }, [_c('v-text-field', {
                ref: "newLineUnitPriceRef",
                staticClass: "text-field-price my-1",
                attrs: {
                  "min": "0",
                  "dense": "",
                  "outlined": "",
                  "hide-details": "auto",
                  "type": "number",
                  "step": "any",
                  "rules": _vm.rulesNewLinePrice
                },
                on: {
                  "input": _vm.newLinePriceChanged
                },
                scopedSlots: _vm._u([{
                  key: "append",
                  fn: function fn() {
                    return [_c('v-icon', {
                      attrs: {
                        "color": "info"
                      }
                    }, [_vm._v(" fas fa-euro-sign ")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.newLinePriceObject.price,
                  callback: function callback($$v) {
                    _vm.$set(_vm.newLinePriceObject, "price", $$v);
                  },
                  expression: "newLinePriceObject.price"
                }
              })], 1), _c('v-col', {
                attrs: {
                  "cols": "auto",
                  "align-self": "center"
                }
              }, [_vm.hasPriceAlgorithm && _vm.newLinePriceObject.computedWithFormula && _vm.newLinePriceObject.price ? _c('v-tooltip', {
                attrs: {
                  "top": ""
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function fn(_ref9) {
                    var on = _ref9.on;
                    return [_c('v-icon', _vm._g({
                      staticClass: "mx-2",
                      class: {
                        'half-transparent': !hover
                      },
                      attrs: {
                        "color": "info",
                        "small": ""
                      }
                    }, on), [_vm._v(" fas fa-calculator ")])];
                  }
                }], null, true)
              }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('PriceComputedWithYourAlgorithm')) + " ")])]) : _vm._e()], 1)], 1)], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('v-tooltip', {
                attrs: {
                  "top": ""
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function fn(_ref10) {
                    var on = _ref10.on;
                    return [_c('v-btn', _vm._g({
                      attrs: {
                        "disabled": _vm.isNewLineEmpty,
                        "fab": "",
                        "x-small": "",
                        "text": "",
                        "rounded": "",
                        "color": "error"
                      },
                      on: {
                        "click": _vm.resetNewLine
                      }
                    }, on), [_c('v-icon', [_vm._v(" fas fa-times ")])], 1)];
                  }
                }], null, true)
              }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('RemoveThisPriceRange')) + " ")])]), _c('v-tooltip', {
                attrs: {
                  "top": "",
                  "disabled": _vm.canAddNewLineQuantity
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function fn(_ref11) {
                    var on = _ref11.on;
                    return [_c('span', _vm._g({}, on), [_c('v-btn', {
                      staticClass: "text-none ml-2",
                      attrs: {
                        "text": _vm.isNewLineEmpty,
                        "color": "success",
                        "disabled": !_vm.canAddNewLine
                      },
                      on: {
                        "click": _vm.addNewLineObject
                      }
                    }, [_c('v-icon', {
                      staticClass: "mr-2"
                    }, [_vm._v(" fas fa-plus-circle ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('Add')) + " ")])], 1)], 1)];
                  }
                }], null, true)
              }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('QuantityAlreadyExists')) + " ")])])], 1)])];
            }
          }], null, false, 4148178164)
        }) : _vm._e()], 2)];
      },
      proxy: true
    }], null, false, 2857965776)
  })], 1) : _vm._e(), _vm.editMode ? _c('v-card-actions', [_c('v-spacer'), _vm.requiresRangeUpdate ? _c('v-btn', {
    staticClass: "text-none px-4 mr-4",
    attrs: {
      "color": "info"
    },
    on: {
      "click": _vm.sortPriceRanges
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-sort-amount-down-alt ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('UpdateRanges')) + " ")])], 1) : _vm._e(), _c('v-tooltip', {
    attrs: {
      "top": "",
      "disabled": _vm.isNewLineEmpty
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref12) {
        var _vm$part$homologation, _vm$part$homologation2;

        var on = _ref12.on;
        return [_c('span', _vm._g({}, on), [_c('v-btn', {
          staticClass: "text-none px-4",
          attrs: {
            "color": "info",
            "disabled": _vm.requiresRangeUpdate || !_vm.areRangesValid || !_vm.isNewLineEmpty,
            "loading": _vm.loading
          },
          on: {
            "click": _vm.savePrices
          }
        }, [_c('v-icon', {
          staticClass: "mr-2"
        }, [_vm._v(" fas fa-save ")]), !((_vm$part$homologation = _vm.part.homologation) !== null && _vm$part$homologation !== void 0 && (_vm$part$homologation2 = _vm$part$homologation.prices) !== null && _vm$part$homologation2 !== void 0 && _vm$part$homologation2.length) ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('SavePrices')) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('SaveModifications')) + " ")])], 1)], 1)];
      }
    }], null, false, 1210314938)
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('PleaseAddOrResetTheNewPriceRange')) + " ")])])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
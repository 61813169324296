var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$part$homologation, _vm$part$homologation2, _vm$part$homologation3, _vm$part$homologation4;

        var hover = _ref.hover;
        return [_c('v-card', _vm._g({
          staticClass: "component-cardOrientationValidation",
          class: {
            'isSelected': _vm.orientationToolEnabled,
            'disabled-card': _vm.part.homologation.status === 'REJECTED'
          },
          attrs: {
            "width": "200px",
            "color": hover && ((_vm$part$homologation = _vm.part.homologation) === null || _vm$part$homologation === void 0 ? void 0 : _vm$part$homologation.status) !== 'REJECTED' ? 'hover' : ((_vm$part$homologation2 = _vm.part.homologation) === null || _vm$part$homologation2 === void 0 ? void 0 : _vm$part$homologation2.status) === 'REJECTED' ? 'rgba(0,0,0,0.12)' : ''
          }
        }, ((_vm$part$homologation3 = _vm.part.homologation) === null || _vm$part$homologation3 === void 0 ? void 0 : _vm$part$homologation3.status) !== 'REJECTED' ? {
          click: _vm.activateReorientationTool
        } : {}), [_c('v-tooltip', {
          attrs: {
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('div', _vm._g({}, on), [_c('v-card-title', {
                staticClass: "pt-1 pb-1 d-flex justify-center px-2"
              }, [_c('span', {
                staticClass: "text-subtitle-1"
              }, [_vm._v(" " + _vm._s(_vm.$t('Orientation')) + " ")])]), _c('v-card-text', {
                staticClass: "py-0 px-2"
              }, [_c('v-img', {
                attrs: {
                  "src": require("../assets/orientation.png"),
                  "width": "100%",
                  "height": "128px",
                  "contain": ""
                }
              })], 1), _c('v-card-actions', {
                staticClass: "pt-1 pb-2 justify-center px-2"
              }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('ReorientPart')) + " ")]), _c('v-icon', {
                staticClass: "ml-2",
                attrs: {
                  "small": "",
                  "color": "info"
                }
              }, [_vm._v(" fas fa-pen ")])], 1)], 1)];
            }
          }], null, true)
        }, [((_vm$part$homologation4 = _vm.part.homologation) === null || _vm$part$homologation4 === void 0 ? void 0 : _vm$part$homologation4.status) !== 'REJECTED' ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('ClickToEnableReorientationTool')) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('CannotModifyPartOrientationCurrently')) + " ")])])], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }
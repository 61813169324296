<template lang="html" src="./pagePart.template.vue"></template>

<style lang="scss" src="./pagePart.scss"></style>

<script>
import {
  BtnMenu,
  DnaFormV3,
  DownloadSupplierPartViewer3dFile,
  EventBus,
  FormAddress,
  ApiErrorParser,
  Viewer3d,
  DownloadSupplierPartFile,
  DownloadSupplierPartOriginalFile,
  ViewerObjectsSplitter,
  ProgressSingle,
  HomologationRejectionMessages,
  SpinnerBeelse
} from '@cloudmanufacturingtechnologies/portal-components';

import SupportEmail from '../../components/supportEmail/SupportEmail';
import SaveBar from '../../components/saveBar/SaveBar';
import ManufacturingInformation from '../../components/manufacturingInformation/ManufacturingInformation';
import PricingRange from '../../components/pricingRange/PricingRange';
import ValidationProblemDialog from '../../components/validationProblemDialog/ValidationProblemDialog';
import PartHomologationValidation from '../../components/partHomologationValidation/PartHomologationValidation';
import RejectHomologationDialog from '../../components/rejectHomologationDialog/RejectHomologationDialog';
import PricingRangesV2 from '../../components/pricingRangesV2/PricingRangesV2';
import PartAttachmentsManager from '../../components/partAttachmentsManager/PartAttachmentsManager';

const i18nData = require('./pagePart.i18n');

export default {
  name: 'PagePart',
  components: {
    BtnMenu,
    DnaFormV3,
    FormAddress,
    Viewer3d,
    SupportEmail,
    SaveBar,
    ManufacturingInformation,
    PricingRange,
    ValidationProblemDialog,
    ViewerObjectsSplitter,
    PartHomologationValidation,
    ProgressSingle,
    RejectHomologationDialog,
    HomologationRejectionMessages,
    PricingRangesV2,
    SpinnerBeelse,
    PartAttachmentsManager
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      cardsColWidth: '672px',
      viewerColWidth: '672px',
      showMainViewer: true,
      lastSizeUpdate: null,
      downloadReq: null,
      downloadPromise: null,
      downloadPartFileObject: null,
      modificationsToolEnabled: false,
      orientationToolEnabled: false,
      serialNumberToolEnabled: false,
      criticalDimensionsToolEnabled: false,
      manufacturingInformationDialog: false,
      priceRangesDialog: false,
      homologationRejectionMessagesDialog: false,
      part: null,
      arrayBuffer: null,
      fullscreen: true,
      partModifications: [],
      loading: false,
      viewer3dFile: null,
      technologiesAndMaterials: null,
      validateQuotationDialog: false,
      viewerFullscreen: false,
      disablePricingRange: false,
      requiresUpdate: false,
      displayViewer: true,
      priceMultiplierPercentage: 100,
      pricesWithPercentage: [],
      useManualPrice: false,
      manualPriceValid: true,
      expansionPanelPrivateWarehouse: [],
      attachmentsDialog: false,
      /**
       * Part file
       */
      downloadChoices: [],
      selectedExtension: '3mf',
      extensionSelectionDialog: false,
      fileDownloadName: '',
      loadingFile: false,
      partFileDataURL: {},
      objectsSplitterFile: null,
      /**
       * Part Quotes
       */
      partQuotes: [],
      updateQuoteDialog: false
    };
  },
  created() {
    window.addEventListener('resize', this.updateColsWidth);
    EventBus.$on('modifyPartOrientation', this.modifySupplierBCMPartOrientation);
    this.updateColsWidth();
    this.getAllTechnologiesAndMaterials();
    this.getSupplierPart();
    this.getSupplierPartQuotes();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateColsWidth);
    EventBus.$off(
      'modifyPartOrientation',
      this.modifySupplierBCMPartOrientation
    );
  },
  computed: {
    currentQuotes() {
      return this.partQuotes.filter(q => {
        return q.status === 'LOCKED'
          && q.dispatch.status === 'NEW';
      });
    },
    currentQuotesValues() {
      const arr = [0];
      for(let i = 1; i < this.currentQuotes.length;  i++) {
        arr.push(i);
      }
      return arr;
    },
    isFromPrivateWarehouse() {
      return this.$appConfig.privateWarehousesObject[this.part?.brand_id];
    },
    performPricing() {
      return !this.part || this.part.homologation.public || this.$appConfig.privateWarehousesObject[this.part.brand_id]?.billing;
    },
    downloadingFile() {
      return this.downloadPartFileObject && (this.downloadPartFileObject.progress !== 100 || this.downloadPartFileObject.status !== 'done');
    },
    billingEnabled() {
      if(this.part.homologation?.public) {
        return true;
      }

      const privateWarehouse = this.$appConfig.privateWarehousesArray.find(pW => {
        return pW._id === this.part.brand_id;
      });

      if(!privateWarehouse || privateWarehouse.billing) {
        return true;
      }

      return false;
    },
    pricesFilled() {
      return this.part?.homologation?.prices?.length > 0;
    },
    manufacturingInformationFilled() {
      return this.part?.homologation?.manufacturingInformations?.printer?.brand && this.part?.homologation?.manufacturingInformations?.printer?.model;
    },
    canBeValidated() {
      return (!this.billingEnabled || this.pricesFilled) && this.manufacturingInformationFilled;
    },
    goBack() {
      if(Object.keys(this.$route.params).includes('quoteUUID')) {
        return {name: 'Quote', params: {supplierUUID: `${this.$route.params.supplierUUID}`, quoteUUID: `${this.$route.params.quoteUUID}`}};
      }else if(Object.keys(this.$route.params).includes('orderUUID')) {
        return {name: 'Order', params: {supplierUUID: `${this.$route.params.supplierUUID}`, orderUUID: `${this.$route.params.orderUUID}`}};
      }else if(Object.keys(this.$route.params).includes('customerUUID')) {
        return {name: 'BcmPartOverview', params: {supplierUUID: `${this.$route.params.supplierUUID}`, customerUUID: `${this.$route.params.customerUUID}`}};
      }else{
        return {name: 'Dashboard', params: {supplierUUID: `${this.$route.params.supplierUUID}`}};
      }
    }
  },
  methods: {
    scrollToCards() {
      document
        .getElementById('PartHomologationValidationId')
        .scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
    renderingReady() {
      this.orientationToolEnabled = false;
      this.serialNumberToolEnabled = false;
      this.criticalDimensionsToolEnabled = false;
      this.modificationsToolEnabled = false;

      if(this.part.modifications) {
        this.displayModifications();
      }

      this.updateColsWidth();
    },
    updateColsWidth() {
      this.showMainViewer = false;

      if(this.$refs.viewer) {
        this.$refs.viewer.minimizeViewer();
      }

      const now = Date.now();
      this.lastSizeUpdate = now;

      const width = window.innerWidth;
      const maxCardsColWidth = width * 5.5 / 10;

      /**
       * We want at least 3 cards per row
       */
      const cardsPerRow = Math.max(3, Math.min(6, Math.floor((maxCardsColWidth) / 224)));
      this.cardsColWidth = `${cardsPerRow * 224 + 40}px`;

      if(cardsPerRow * 224 + 40 > width * 0.55) {
        /**
         * Will wrap
         */
        this.viewerColWidth = `${width}px`;
      } else {
        const maxViewerColWidth = (width * 9.5 / 10) - (cardsPerRow * 224 + 40);
        this.viewerColWidth = `${maxViewerColWidth}px`;
      }

      setTimeout(() => {
        if(now === this.lastSizeUpdate) {
          this.showMainViewer = true;
          if(this.$refs.viewer) {
            setTimeout(() => {
              this.$refs.viewer.updateViewerSize();
            }, 50);
          }
        }
      },150);
    },
    /**
     *  GET ALL TECHNOLOGIES AND MATERIALS
     */
    getAllTechnologiesAndMaterials() {
      this.$apiInstance
        .getAllTechnologiesAndMaterialsCompatibilities(
          this.$route.params.supplierUUID,
          this.$route.params.partUUID
        )
        .then(
          (technologiesAndMaterials) => {
            this.technologiesAndMaterials = technologiesAndMaterials;
          },
          /**
           * ERROR GET ALL TECHNOLOGIES AND MATERIALS
           */
          (error) => {
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
    /**
     * GET SUPPLIER PART
     */
    getSupplierPart() {
      this.downloadChoices = [
        {
          text: '.STL',
          value: 'stl'
        },
        {
          text: '.3MF',
          value: '3mf'
        }
      ];
      this.$apiInstance
        .getSupplierPart(
          this.$route.params.supplierUUID,
          this.$route.params.partUUID
        )
        .then(
          (partData) => {
            this.initPartDataHomologation(partData);
          },
          /**
           * ERROR GET SUPPLIER PART
           */
          (error) => {
            this.$notification.notify('DANGER',  this.$t(ApiErrorParser.parse(error)));
          }
        )
        .finally(() => {
          if (this.requiresUpdate) {
            EventBus.$emit('priceUpdated');
            this.requiresUpdate = false;
          }
        });
    },
    initPartDataHomologation(partData) {
      const partH = partData.homologation;

      /**
       * Prepare manufacturing informations
       */
      if(!partH.manufacturingInformations) {
        partH.manufacturingInformations = {};
      }

      const partMI = partH.manufacturingInformations;
              
      if(!partMI.printer) {
        partMI.printer = {};
      } else {
        if (partMI.printer.brand) {
          partMI.printer.brand = partMI.printer.brand
            .split('_')
            .join(' ');
        }
        if (partMI.printer.model) {
          partMI.printer.model = partMI.printer.model
            .split('_')
            .join(' ');
        }
      }
      /** Get material in homologation and prefill the field */

      if (partH.material && !partMI.material) {

        partMI.material = this.$t(partH.material) + ' - ' + this.$t(partData.dna.configuration.color.original);

      }

      if(!partMI.finish) {
        const translatedFinishes = [];
        partMI.finish = '';

        if(partData.dna.configuration.color.dyeing) {
          translatedFinishes.push(this.$t('Dyeing') + ' : ' + this.$t(partData.dna.configuration.color.dyeing));
        }

        partData.dna.configuration.finishes.default.forEach((finish) => {
          translatedFinishes.push(this.$t(finish));
        });

        if (partData.dna.configuration.finishes.optional) {
          partData.dna.configuration.finishes.optional.forEach((finish) => {
            translatedFinishes.push(this.$t(finish));
          });
        }

        partMI.finish += translatedFinishes.join('\n');
      }

      if(!partMI.manufacturing_parameters && partData.dna.technology === 'FFF') {

        const translatedFFFConfiguration = [];
        partMI.manufacturing_parameters = '';

        translatedFFFConfiguration.push(this.$t('LayerSize') + ' : ' + partData.dna.configuration.fffConfiguration.layerSize + 'mm');
        translatedFFFConfiguration.push(this.$t('Infill') + ' : ' + partData.dna.configuration.fffConfiguration.infill + '%');

        partMI.manufacturing_parameters += translatedFFFConfiguration.join('\n');

      }

      this.part = partData;

      if(partH.status === 'REJECTED') {
        this.expansionPanelPrivateWarehouse = 0;
      }

      this.priceMultiplierPercentage = this.part.homologation.priceMultiplierPercentage;

      /**
       * Get part modifications (if any) & part viewer file
       */
      this.getPartModifications();

      if(partData.dna.technology === 'MJF' && partData.dna.material === 'cb_pa_12') {
        /**
         * Allow only 3MF download
         */
        this.downloadChoices.shift();
      } else if(this.part.originalFile && !this.part.serialNumberSettings) {
        /**
         * Add STEP file choice
         */
        this.downloadChoices.splice(0, 0, {
          text: `.${this.part.originalFile.extension.toUpperCase()}`,
          value: this.part.originalFile.extension
        },);
      }
    },
    getPartModifications() {
      if(this.part.modifications) {
        this.$apiInstance.getSupplierPartModifications(
          this.$route.params.supplierUUID,
          this.$route.params.partUUID
        ).then((data) => {
          this.partModifications = data;
        }, (error) => {
        /**
         * No modifications, nothing to do
         */
        }).finally(() => {
          this.getPartViewer3dFile();
        });
      } else {
        this.getPartViewer3dFile();
      }
    },
    openViewerObjectsSplitter() {
      this.getPartFileForViewerSplitter();
      this.$refs.viewerObjectsSplitterRef.open();
    },
    getPartFileForViewerSplitter() {
      if(!this.objectsSplitterFile) {
        DownloadSupplierPartFile.downloadSupplierPartFile(
          this.$apiInstance,
          this.$route.params.supplierUUID,
          this.$route.params.partUUID,
          'stl'
        )[0].then((response) => {
          this.objectsSplitterFile = {
            extension: response.extension,
            buffer: Buffer.from(response.buffer),
          };
        });
      }
    },
    /**
     * GET PART FILE
     */
    getPartFile(extension = null) {

      if(extension) {
        this.selectedExtension = extension;
      }

      this.closeExtensionSelectionDialog();

      extension = extension || 'stl';

      this.fileDownloadName = this.part.name + '.' + extension;
      this.loadingFile = true;

      const button = this.$refs.downloadFileButton;

      if(!this.partFileDataURL[extension]) {

        let promise, req = null;

        if(['step', 'obj', 'iges'].includes(extension)) {
          [promise, req] = DownloadSupplierPartOriginalFile.downloadSupplierPartOriginalFile(
            this.$apiInstance,
            this.$route.params.supplierUUID,
            this.$route.params.partUUID,
          );
        } else {
          [promise, req] = DownloadSupplierPartFile.downloadSupplierPartFile(
            this.$apiInstance,
            this.$route.params.supplierUUID,
            this.$route.params.partUUID,
            extension
          );
        }

        this.downloadReq = req;
        this.downloadPromise = promise;

        const downloadPartFileObject = {
          progress: 0,
          progressRaw: 0,
          total: 1,
          status: 'idle',
          postOperationsFinished: 0,
        };

        this.downloadPartFileObject = downloadPartFileObject;

        req.onprogress = function(event) {
          downloadPartFileObject.status = 'progress';
          downloadPartFileObject.total = event.total;
          downloadPartFileObject.progressRaw = event.loaded;
          downloadPartFileObject.progress = Math.floor(100 * event.loaded / event.total);
        };

        req.onerror = function(event) {
          downloadPartFileObject.status = 'error';
        };

        req.ontimeout = function(event) {
          downloadPartFileObject.status = 'error';
        };

        promise.then((response) => {
          const byteArray = new Uint8Array(response.buffer);
          const blob = new Blob([byteArray], {
            type: 'application/octet-stream',
          });

          if(!this.objectsSplitterFile) {
            this.objectsSplitterFile = {
              extension: response.extension,
              buffer: Buffer.from(response.buffer)
            };
          }

          this.partFileDataURL[extension] = URL.createObjectURL(blob);
          button.href = this.partFileDataURL[extension];
          this.loadingFile = false;
          downloadPartFileObject.status = 'done';
          setTimeout(() => {
            button.click();
          }, 250);
        });

      } else {

        this.downloadPartFileObject.status = 'progress';
        button.href = this.partFileDataURL[extension];
        setTimeout(() => {
          this.loadingFile = false;
          this.downloadPartFileObject.status = 'done';
          button.click();
        }, 1000);

      }

    },
    /**
     * GET PART VIEWER3D FILE
     */
    getPartViewer3dFile() {
      const oReq = DownloadSupplierPartViewer3dFile.downloadSupplierPartViewer3dFile(
        this.$apiInstance,
        this.$route.params.supplierUUID,
        this.$route.params.partUUID,
        new Date(this.part.viewer3dFile.created).getTime()
      );
      oReq.onloadend = () => {
        this.viewer3dFile = {
          extension: 'blsv',
          buffer: Buffer.from(oReq.response),
        };
      };
    },
    /**
     * SAVING MANUFACTURING INFORMATIONS
     */
    saveManufacturingInformation(manufacturingInformation) {
      if(this.part.homologation.status === 'REJECTED') {
        return;
      }

      const partMI = manufacturingInformation;

      partMI.printer.brand = partMI.printer.brand.replace(
        /\s+/g,
        '_'
      );
      partMI.printer.model = partMI.printer.model.replace(
        /\s+/g,
        '_'
      );

      const manufacturingInformations = new this.$BcmModel.ManufacturingInformations(
        partMI.printer,
        partMI.material
      );
      manufacturingInformations.manufacturing_parameters = partMI.manufacturing_parameters;
      manufacturingInformations.finish = partMI.finish;
      this.$apiInstance
        .modifySupplierPartManufacturingInformations(
          this.$route.params.supplierUUID,
          this.$route.params.partUUID,
          manufacturingInformations
        )
        .then(
          () => {
            this.$notification.notify('SUCCESS',  this.$t('ModifiedManufacturingInformationsSuccessfully'));
            this.getSupplierPart();
            this.manufacturingInformationDialog = false;
          },
          (error) => {
            this.$notification.notify('DANGER',  ApiErrorParser.parse(error));
          }
        ).finally(() => {
          this.$refs.manufacturingInformationRef.stopLoading();
        });
    },
    updatePricesWithPercentage(prices, percentage) {
      this.priceMultiplierPercentage = percentage;
      this.pricesWithPercentage = prices;
    },
    /**
     * SAVING PRICES FORM
     */
    savePrices(prices, percentage) {
      const modifyPartHomologationPurchasePricesBody = this.$BcmModel.ModifyPartHomologationPurchasePricesBody.constructFromObject({
        priceMultiplierPercentage: percentage,
        prices: prices
      });

      this.$apiInstance
        .modifySupplierPartHomologationPurchasePrices(
          this.$route.params.supplierUUID,
          this.part._id,
          modifyPartHomologationPurchasePricesBody
        )
        .then(
          (part) => {
            this.$notification.notify('SUCCESS',  this.$t('ModifiedPricesSuccessfully'));
            this.priceRangesDialog = false;
            if(this.currentQuotes.length > 0) {
              this.part = part;
              this.updateQuoteDialog = true;
            }
          },
          (error) => {
            this.$notification.notify('DANGER',  this.$t(ApiErrorParser.parse(error)));
          }
        ).finally(() => {
          this.getSupplierPart();
          this.$refs.priceRangesV2Ref.stopLoading();
        });
    },
    displayCriticalDimensions() {
      this.$refs.viewer.prepareNextToolbarAction('criticalDimensions');
    },
    displayModifications() {
      this.$refs.viewer.prepareNextToolbarAction('modifications');
    },
    displaySerialNumber() {
      this.$refs.viewer.prepareNextToolbarAction('serialNumber');
    },
    displayRotation() {
      this.$refs.viewer.prepareNextToolbarAction('rotation');
    },
    openRejectHomologationDialog() {
      this.$refs.rejectHomologationDialogRef.openDialog();
    },
    rejectPartHomologation(title, message) {
      const rejectSupplierPartHomologationBody = new this.$BcmModel.RejectSupplierPartHomologationBody(title, message);

      this.$apiInstance.rejectSupplierPartHomologation(
        this.$route.params.supplierUUID,
        this.$route.params.partUUID,
        rejectSupplierPartHomologationBody
      ).then((data) => {
        this.getSupplierPart();
        this.$refs.rejectHomologationDialogRef.closeDialog();
        this.$notification.notify(
          'SUCCESS',
          this.$t('HomologationSuspended')
        );
      }, (error) => {
        this.$notification.notify(
          'ERROR',
          ApiErrorParser.parse(error)
        );
      });
    },
    handlerStartEditing(tool) {
      switch(tool) {
      case 'rotation':
        this.orientationToolEnabled = true;
        break;
      case 'serialNumber':
        this.serialNumberToolEnabled = true;
        break;
      case 'criticalDimensions':
        this.criticalDimensionsToolEnabled = true;
        break;
      case 'modifications':
        this.modificationsToolEnabled = true;
        break;
      default:
        break;
      }
    },
    handlerExitEdition(value, tool = null) {
      if(value) {
        switch(tool) {
        case 'rotation':
          this.orientationToolEnabled = false;
          break;
        case 'serialNumber':
          this.serialNumberToolEnabled = false;
          break;
        case 'criticalDimensions':
          this.criticalDimensionsToolEnabled = false;
          break;
        case 'modifications':
          this.modificationsToolEnabled = false;
          break;
        default:
          break;
        }
      }
    },
    modifySupplierBCMPartOrientation(uuid, rotations) {
      if(this.part.homologation.status === 'REJECTED') {
        return;
      }
      const modifySupplierBCMPartOrientationBody = new this.$BcmModel.ModifySupplierBCMPartOrientationBody(
        rotations
      );
      this.$apiInstance
        .modifySupplierBCMPartOrientation(
          this.$route.params.supplierUUID,
          this.$route.params.partUUID,
          modifySupplierBCMPartOrientationBody
        )
        .then(
          (data) => {
            this.orientationToolEnabled = false;
            this.$notification.notify(
              'SUCCESS',
              this.$t('SuccessReOrientation')
            );
            this.part.rotationHistory = data.rotationHistory;
            this.part.analysisStatus = data.analysisStatus;
            this.partFileMD5 = null;
            this.partFileDataURL = {};
            this.displayViewer = false;
            setTimeout(() => {this.displayViewer = true;}, 250);
            this.getSupplierPartWhileAnalysisInProgress();
          },
          (error) => {
            this.$notification.notify('DANGER',  ApiErrorParser.parse(error), { timeout: 10000 });
          }
        );
    },
    getSupplierPartWhileAnalysisInProgress() {
      this.$apiInstance
        .getSupplierPart(
          this.$route.params.supplierUUID,
          this.$route.params.partUUID
        )
        .then((data) => {
          this.part = data;
          if(this.part.analysisStatus !== 'DONE' && this.part.analysisStatus !== 'FAILED') {
            setTimeout(this.getSupplierPartWhileAnalysisInProgress, 3000);
          } else {
            this.initPartDataHomologation(data);
          }
        });
    },
    changePriceFormValidation(value) {
      this.manualPriceValid = value;
    },
    /**
     * EMIT EVENT TO A CALL SUPPORT AND DISPLAY MODAL
     */
    callSupport() {
      this.$refs.supportForPriceRequest.dialogSupportEmail = true;
    },
    /**
     * SWITCH FOR FULL SCREEN
     */
    switchFullscreen(e) {
      this.viewerFullscreen = e;
    },
    /**
     * SEND QUOTATION TO CMT
     */
    sendQuotationToBCM() {
      this.loading = true;
      this.$apiInstance
        .sendHomologationToBCM(this.$route.params.supplierUUID, this.part._id)
        .then(
          () => {
            this.$notification.notify('SUCCESS',  this.$t('InformationSaved'));
            this.validateQuotationDialog = true;
          },
          (error) => {
            this.$notification.notify('DANGER', ApiErrorParser.parse(error));
          }
        ).finally(() => {
          this.loading = false;
          this.getSupplierPart();
        });
    },
    openExtensionSelectionDialog() {
      if(!this.downloadingFile) {
        this.extensionSelectionDialog = true;
      }
    },
    closeExtensionSelectionDialog() {
      this.extensionSelectionDialog = false;
    },
    openHomologationRejectionMessagesDialog() {
      this.homologationRejectionMessagesDialog = true;
    },
    closeHomologationRejectionMessagesDialog() {
      this.homologationRejectionMessagesDialog = false;
    },
    openManufacturingParametersDialog() {
      this.manufacturingInformationDialog = true;
    },
    closeManufacturingParametersDialog() {
      this.manufacturingInformationDialog = false;
    },
    openPricesDialog() {
      this.priceRangesDialog = true;
    },
    closePricesDialog() {
      this.priceRangesDialog = false;
    },
    openAttachments() {
      this.attachmentsDialog = true;
    },
    modifyHomologationRejectionInformation(title, message, date, index) {
      const modifySupplierPartHomologationRejectionInformationBody = new this.$BcmModel.ModifySupplierPartHomologationRejectionInformationBody(
        title,
        message,
        date
      );

      this.$apiInstance.modifySupplierPartHomologationRejectionInformation(
        this.$route.params.supplierUUID,
        this.$route.params.partUUID,
        modifySupplierPartHomologationRejectionInformationBody
      ).then(data => {
        this.$notification.notify('SUCCESS', this.$t('RejectionMessageModifiedSuccessfully'));
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.$refs.homologationRejectionMessages.stopLoading();
        this.getSupplierPart();
      });
    },
    editParameterFromPriceDialog() {
      this.closePricesDialog();
      this.openManufacturingParametersDialog();
    },
    /**
     * Update quote when prices change
     */
    getSupplierPartQuotes() {
      this.partQuotes = [];
      this.$apiInstance.getSupplierQuotes(this.$route.params.supplierUUID, {partUUID: this.$route.params.partUUID})
        .then(data => {
          this.partQuotes = data;
        });
    },
    async updateQuotesWithNewPrices() {
      //ForEach Quote
      for await (const q of this.currentQuotes) {
        //Get quote
        const quote = await this.getQuote(q._id);
        // For each dispatch items 
        quote.dispatch.items.forEach(item =>{
          // find the item
          if(item.part._id === this.part._id) {
            const price = this.part.homologation.prices.findLast(price => price.quantity <= item.quantity);
            //Update item
            const modifySupplierQuoteDispatchItemPurchasePriceBody = new this.$BcmModel.ModifySupplierQuoteDispatchItemPurchasePriceBody(
              Number(price.purchasePrice),
              Number(item.purchase.vatPercentage)
            );
            this.modifySupplierQuoteDispatchItemPurchasePrice(quote, this.$route.params.supplierUUID, this.part._id, modifySupplierQuoteDispatchItemPurchasePriceBody);
          }
        });
      }
      this.updateQuoteDialog = false;
    },
    async getQuote(quoteUUID) {
      const quote = await this.$apiInstance.getSupplierQuote(this.$route.params.supplierUUID, quoteUUID).then(
        (quoteData) => {
          return quoteData;
        },
        (error) => {
          /**
           * ERROR GET QUOTE
           *
           * Component BeelseNotifications used
           */
          this.$notification.notify('DANGER', ApiErrorParser.parse(error));
        }
      );
      return quote;
    },
    modifySupplierQuoteDispatchItemPurchasePrice(quote, supplierUUID, partUUID, modifySupplierQuoteDispatchItemPurchasePriceBody) {
      this.$apiInstance.modifySupplierQuoteDispatchItemPurchasePrice(
        supplierUUID,
        quote._id,
        partUUID,
        modifySupplierQuoteDispatchItemPurchasePriceBody
      ).then(() => {
        this.$notification.notify('SUCCESS', this.$t('partPriceUpdatedQuote', {name: quote.quoteNumber}));
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      });
    }
  },
};
</script>

<template lang="html" src="./cardManufacturingParametersValidation.template.vue"></template>

<style lang="scss" src="./cardManufacturingParametersValidation.scss"></style>

<script>

const i18nData = require('./cardManufacturingParametersValidation.i18n');
export default {
  name: 'CardManufacturingParametersValidation',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  props: {
    part: {
      type: Object,
      required: true
    },
    disableCardHighlight: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    };
  },
  computed: {
    areManufacturingParametersSet() {
      return this.part.homologation?.manufacturing_parameters?.printer?.brand && this.part.homologation?.manufacturing_parameters?.printer?.model;
    },
    isMissing() {
      return !this.part.homologation?.manufacturingInformations?.printer?.brand || !this.part.homologation?.manufacturingInformations?.printer?.model;
    }
  },
  created() {
    
  },
  methods: {
    openManufacturingParametersDialog() {
      this.$emit('openManufacturingParametersDialog');
    },
  },
};
</script>
